import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../models/users.models';
import { PassRequest } from '../models/pass-request';


@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(private _http: HttpClient) {}

    private apiUrl = environment.apiUrl;


    public getUsers(){
        return this._http.get(this.apiUrl+ '/users');
    }

    public createUser(data : User){
        return this._http.post(this.apiUrl+ '/users', data);
    }

    public modifyUser(data : User){
        return this._http.put(this.apiUrl+ '/users', data);
    }

    public getUserByID(id : number){
        return this._http.get(this.apiUrl+ '/users/'+ id);
    }

    public changePass(email : string, pass: string){
        let passReq = new PassRequest();
        passReq.email = email;
        passReq.pass = pass;
        return this._http.put(this.apiUrl+ '/change-pass/', passReq);
    }
}
  