<ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" 
    id="accordionSidebar"
    [ngClass]="{'toggled': statusMenu}">

    <!-- Sidebar - Brand -->
    <a class="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
        <div class="sidebar-brand-icon rotate-n-15">
        
        </div>
        <div class="sidebar-brand-text mx-3">Admin</div>
    </a>

    <!-- Divider -->
    <hr class="sidebar-divider my-0">
    <!-- Divider -->
    <hr class="sidebar-divider">

    <!-- Nav Item - Pages Collapse Menu -->
    <li class="nav-item">
        <a class="nav-link" data-toggle="collapse" data-target="#collapsePages"
            (click)="toogleOption('Configuration')"
            aria-expanded="true" aria-controls="collapsePages">
            <i class="fas fa-fw fa-folder"></i>
            <span>Configurations</span>
        </a>
        <div id="collapsePages" [ngClass]="{'collapse': !optionconfig}" aria-labelledby="headingPages" data-parent="#accordionSidebar">
            <div class="bg-white py-2 collapse-inner rounded">   
                <li class="nav-item">
                    <a class="nav-link submenu-link" [routerLink]="['/auth/bookings']">
                        <i class="fa-solid fa-table-list"></i>
                        <span>Bookings</span>
                    </a>
                </li>       
                <li class="nav-item">
                    <a class="nav-link submenu-link" [routerLink]="['/auth/quotes']">
                        <i class="fa-solid fa-table-list"></i>
                        <span>Quotes</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link submenu-link" [routerLink]="['/auth/parameter']">
                        <i class="fa-solid fa-table-list"></i>
                        <span>Parameters</span>
                    </a>
                </li>

            </div>
        </div>
    </li>
    <li class="nav-item">
        <a class="nav-link" data-toggle="collapse" data-target="#collapsePages"
            (click)="toogleOption('Blogs')"
            aria-expanded="true" aria-controls="collapsePages">
            <i class="fas fa-fw fa-folder"></i>
            <span>Blog</span>
        </a>
        <div id="collapsePages" [ngClass]="{'collapse': !optionBlog}" aria-labelledby="headingPages" data-parent="#accordionSidebar">
            <div class="bg-white py-2 collapse-inner rounded">
            
                <li class="nav-item">
                    <a class="nav-link submenu-link" [routerLink]="['/auth/blog-category']">
                        <i class="fas fa-fw fa-list-ul"></i>
                        <span>Blog Category</span>
                    </a>
                </li>            
                <li class="nav-item">
                    <a class="nav-link submenu-link" [routerLink]="['/auth/blogs']">
                        <i class="fas fa-fw fa-book"></i>
                        <span>Blog</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link submenu-link" [routerLink]="['/auth/press']">
                        <i class="fas fa-fw fa-book"></i>
                        <span>Press</span>
                    </a>
                </li>
            </div>
        </div>
    </li>

    
    <li class="nav-item">
        <a class="nav-link" data-toggle="collapse" data-target="#collapsePages"
            (click)="toogleOption('Partners')"
            aria-expanded="true" aria-controls="collapsePages">
            <i class="fas fa-fw fa-folder"></i>
            <span>Partners</span>
        </a>
        <div id="collapsePages" [ngClass]="{'collapse': !optionPartner}" aria-labelledby="headingPages" data-parent="#accordionSidebar">
            <div class="bg-white py-2 collapse-inner rounded">
                <li class="nav-item">
                    <a class="nav-link submenu-link" [routerLink]="['/auth/partner-log']">
                        <i class="fa-solid fa-handshake"></i>
                        <span>Partnerships Registration</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link submenu-link" [routerLink]="['/auth/partners']">
                        <i class="fa fa-people-group"></i>
                        <span>Partners</span></a>
                </li>            
            </div>
        </div>
    </li>

    <!-- Menu Venues Contain -->
    <li class="nav-item">
        <a class="nav-link" data-toggle="collapse" data-target="#collapseVenues"
            (click)="toogleOption('Venues')"
            aria-expanded="true" aria-controls="collapseVenues">
            <i class="fas fa-fw fa-folder"></i>
            <span>Venues</span>
        </a>
        <div id="collapseVenues" [ngClass]="{'collapse': !optionVenues}" aria-labelledby="headingPages" data-parent="#accordionSidebar">
            <div class="bg-white py-2 collapse-inner rounded">   
                <li class="nav-item">
                    <a class="nav-link submenu-link" [routerLink]="['/auth/venues/videos']">
                        <i class="fa-solid fa-table-list"></i>
                        <span>Videos</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link submenu-link" [routerLink]="['/auth/venues/reviews']">
                        <i class="fa-solid fa-table-list"></i>
                        <span>Reviews</span>
                    </a>
                </li>
            </div>
        </div>
    </li>

    <!-- Nav Item - Charts -->
    <li class="nav-item">
        <a class="nav-link" data-toggle="collapse" data-target="#collapsePages"
            (click)="toogleOption('Service')"
            aria-expanded="true" aria-controls="collapsePages">
            <i class="fas fa-fw fa-user"></i>
            <span>Services</span>
        </a>
        <div id="collapsePages" [ngClass]="{'collapse': !optionService}" aria-labelledby="headingPages" data-parent="#accordionSidebar">
            <div class="bg-white py-2 collapse-inner rounded">    
                <li class="nav-item">
                    <a class="nav-link submenu-link"  [routerLink]="['/auth/services']">
                        <i class="fa-solid fa-puzzle-piece"></i>
                        <span>Services</span></a>
                </li>   
                <li class="nav-item">
                    <a class="nav-link submenu-link"  [routerLink]="['/auth/add-ons-categories']">
                        <i class="fa-solid fa-puzzle-piece"></i>
                        <span>Add-ons categories</span></a>
                </li>            
                <li class="nav-item">
                    <a class="nav-link submenu-link"  [routerLink]="['/auth/add-ons']">
                        <i class="fa-solid fa-puzzle-piece"></i>
                        <span>Add-ons</span></a>
                </li>            
                <li class="nav-item">
                    <a class="nav-link submenu-link"  [routerLink]="['/auth/locations']">
                            <i class="fa-solid fa-location-dot"></i>
                        <span>Locations</span>
                    </a>
                </li>                
                <li class="nav-item">
                    <a class="nav-link submenu-link"  [routerLink]="['/auth/ports']">
                        <i class="fa-solid fa-anchor"></i>
                        <span>Marinas</span>
                    </a>
                </li>            
                <li class="nav-item">
                    <a class="nav-link submenu-link"  [routerLink]="['/auth/water-toys']">
                        <i class="fa-solid fa-water"></i>
                        <span>Water Toys</span>
                    </a>
                </li>
                
                <li class="nav-item">
                    <a class="nav-link submenu-link"  [routerLink]="['/auth/experience-enhancers']">
                        <i class="fa-solid fa-water"></i>
                        <span>Experience Enhancers</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link submenu-link" [routerLink]="['/auth/type-boats']">
                        <i class="fa-solid fa-sailboat"></i>
                        <span>Type boats</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link submenu-link" [routerLink]="['/auth/amenities']">
                        <i class="fa-solid fa-umbrella-beach"></i>
                        <span>Amenities</span>
                    </a>
                </li>
                
                <li class="nav-item">
                    <a class="nav-link submenu-link" [routerLink]="['/auth/ceremony-types']">
                        <i class="fa-solid fa-cake-candles"></i>
                        <span>Type of Events Available</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link submenu-link" [routerLink]="['/auth/specifications']">
                        <i class="fa-solid fa-list-check"></i>
                        <span>Specifications</span>
                    </a>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link submenu-link" [routerLink]="['/auth/recommendation']">
                        <i class="fa-solid fa-gear"></i>
                        <span>Recommendations</span> 
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link submenu-link" [routerLink]="['/auth/type-recommendations']">
                        <i class="fa-solid fa-clipboard-list"></i>
                        <span>Type Recommendations</span>
                    </a>
                </li>                -->
                <li class="nav-item">
                    <a class="nav-link submenu-link" [routerLink]="['/auth/status-yacht']">
                        <i class="fa-solid fa-gear"></i>
                        <span>Status Yachts</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link submenu-link" [routerLink]="['/auth/things-know']">
                        <i class="fa-solid fa-gear"></i>
                        <span>Things Know</span>
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link submenu-link" [routerLink]="['/auth/tag-name']">
                        <i class="fa-solid fa-gear"></i>
                        <span>Tag Names</span>
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link submenu-link" [routerLink]="['/auth/include-services']">
                        <i class="fa-solid fa-gear"></i>
                        <span>Included Services</span>
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link submenu-link" [routerLink]="['/auth/trusted-logos']">
                        <i class="fa-solid fa-gear"></i>
                        <span>Trusted Logos</span>
                    </a>
                </li>
            </div>
        </div>      
    </li>    

    <li class="nav-item">
        <a class="nav-link" [routerLink]="['/auth/users']">
            <i class="fas fa-fw fa-user"></i>
            <span>Users</span></a>
    </li>
   

    <!-- Divider -->
    <hr class="sidebar-divider d-none d-md-block">

    <!-- Sidebar Toggler (Sidebar) -->
    <div class="text-center d-none d-md-inline">
        <button class="rounded-circle border-0" id="sidebarToggle" (click)="toogleMenu()"></button>
    </div>



</ul>