<div class="bg-default container--login">
    <div class="container">

        <!-- Outer Row -->
        <div class="row justify-content-center">

            <div class="col-xl-10 col-lg-12 col-md-9">

                <div class="card o-hidden border-0 shadow-lg my-5">
                    <div class="card-body p-0">
                        <!-- Nested Row within Card Body -->
                        <div class="row">
                            <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
                            <div class="col-lg-6">
                                <div class="p-5">
                                    <div class="text-center">
                                        <h1 class="h4 text-gray-900 mb-4">Welcome Back!</h1>
                                    </div>
                                    <form class="user"
                                        (submit)="sendForm()"
                                        [formGroup]="loginForm" 
                                        autocomplete="off">
                                        <div class="form-group">
                                            <input 
                                                type="email" 
                                                class="form-control form-control-user"
                                                id="email" 
                                                aria-describedby="emailHelp"
                                                placeholder="Enter Email Address..."
                                                formControlName="email"
                                                autocomplete="off">
                                            <small class="text-danger pr-error" *ngIf="noValidField('email')">
                                                {{ listErrorsMessages("email") }}
                                            </small>
                                        </div>
                                        <div class="form-group">
                                            <input type="password" 
                                                class="form-control form-control-user"
                                                id="pass" 
                                                placeholder="Password"
                                                formControlName="pass"
                                                autocomplete="off">
                                            <small class="text-danger pr-error" *ngIf="noValidField('pass')">
                                                {{ listErrorsMessages("pass") }}
                                            </small>
                                        </div>

                                        <button
                                            class="btn btn-primary btn-user btn-block"
                                            [disabled]="!loginForm.valid"
                                            type="submit">
                                            Login
                                        </button>
                                        <hr>

                                    </form>
                                    <hr>
                                    <div *ngIf="errorMessage">
                                        <span class="text-danger pr-error">{{errorMessage}}</span>
                                    </div>
                                    <div class="text-center">
                                        <a class="small"[routerLink]="['/auth/forgot-pass']">Forgot Password?</a>
                                    </div>
                                    <div class="text-right">
                                        <a class="small fnt-orange" [routerLink]="['/home']">Go to Web Page</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>
</div>