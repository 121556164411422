
<div >

    <div class="container">

        <!-- Outer Row -->
        <div class="row justify-content-center">

            <div class="col-xl-10 col-lg-12 col-md-9">

                <div class="card o-hidden border-0 shadow-lg my-5">
                    <div class="card-body p-0">
                        <!-- Nested Row within Card Body -->
                        <div class="row">
                            <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
                            <div class="col-lg-6">
                                <div class="p-5">
                                    <div class="text-center">
                                        <h1 class="h4 text-gray-900 mb-2">Forgot Your Password?</h1>
                                        <p class="mb-4">We get it, stuff happens. Just enter your email address below
                                        </p>
                                    </div>
                                    <form 
                                        autocomplete="off"
                                        [formGroup]="passForm"
                                        (submit)="sendForm()">
                                        <div class="form-group">
                                            <input type="email" 
                                                class="form-control form-control-user"
                                                id="email" 
                                                aria-describedby="emailHelp"
                                                placeholder="Email"
                                                formControlName="email">
                                            <small class="text-danger pr-error" *ngIf="noValidField('email')">
                                                {{ listErrorsMessages("email") }}
                                            </small>
                                        </div>
                                        <div class="form-group">
                                            <input type="password" 
                                                class="form-control form-control-user"
                                                id="pass" 
                                                aria-describedby="pass"
                                                placeholder="Enter pass"
                                                formControlName="pass">
                                            <small class="text-danger pr-error" *ngIf="noValidField('pass')">
                                                {{ listErrorsMessages("pass") }}
                                            </small>
                                        </div>
                                        <div class="form-group">
                                            <input type="password" 
                                                class="form-control form-control-user"
                                                id="confirmPass" 
                                                aria-describedby="confirmPass"
                                                placeholder="Confirm pass"
                                                formControlName="confirmPass">
                                            <small class="text-danger pr-error" *ngIf="noValidField('confirmPass')">
                                                {{ listErrorsMessages("confirmPass") }}
                                            </small>

                                            
                                            <small class="text-danger pr-error" *ngIf="passForm.get('confirmPass')?.errors?.['mustMatch']">
                                                Passwords must match
                                            </small>
                                        </div>
                                        <button 
                                            type="submit"
                                            class="btn btn-primary 
                                            btn-user btn-block"
                                            [disabled]="!passForm.valid">
                                            Reset Password
                                        </button>
                                    </form>
                                    <hr>
                                    <div *ngIf="sucessMessage">
                                        <span class="text-success pr-error">{{sucessMessage}}</span>
                                    </div>
                                    <div *ngIf="errorMessage">
                                        <span class="text-danger pr-error">{{errorMessage}}</span>
                                    </div>
                                    <div class="text-center">
                                        <a class="small" [routerLink]="['/auth/sign-in']">Return to Sign in!</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>
</div>
