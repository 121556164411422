import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageNotfoundComponent } from './page-notfound/page-notfound.component';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { FooterComponent } from './layouts/admin-layout/footer/footer.component';

import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { MenuComponent } from './layouts/auth-layout/menu/menu.component';
import { HeaderAuthComponent } from './layouts/auth-layout/header-auth/header-auth.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SignInComponent } from './sign-in/sign-in.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from './core/services/auth.service';
import { ForgotPassComponent } from './forgot-pass/forgot-pass.component';
import { RouterModule } from '@angular/router';
import { ShareComponent } from './shared/share/share.component';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { PaymentFailedComponent } from './payment-failed/payment-failed.component';
import { AsSeenOnComponent } from './components/as-seen-on/as-seen-on.component';

@NgModule({
  declarations: [
    AppComponent,
    PageNotfoundComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    MenuComponent,
    HeaderAuthComponent,
    SignInComponent,
    ForgotPassComponent,
    PaymentFailedComponent,
    
  ],
  imports: [
    HttpClientModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    ReactiveFormsModule,
    NgbModule,
    RouterModule,
    NoopAnimationsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [AuthService],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor(private meta: Meta){}
}
