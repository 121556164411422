
<div class="grid-element">
    <div class="grid-child">            
        <div class="grid-data">
            <header>
                <div class="logo">
                <a [routerLink]="['/']">
                    <div classs="img" >
                    <img  class="img-1" src="assets/img/logo_light.svg" alt="Prime Experiencess" width="116">
                    </div> 
                </a>
                </div>
            </header>            
            <div id="text" style="  width: 80% !important;">
                <p id="thank">404</p><br><p id="you" >NOT FOUND</p> 
                <p class="sorry">Sorry, the page you’re looking for doesn’t exist. If you think something is broken, report a problem</p>   
                <div class="row"> 
                    <div class="col-lg-5 col-md-5 take">
                        <a href="">
                            <button type="button" class="btn btn-orange ">
                                <span class="btn-orange btn-orange-1" >
                                    TAKE ME BACK HOME
                                </span> 
                            </button>
                        </a>
                    </div>
                    <br>
                    <br>
                    <div class="col-lg-2 col-md-2">
                        <p class="or">OR</p>
                    </div>
                    <br>
                    <br>
                    <div class="col-lg-5 col-md-5 report">
                        <button class="btn-recommended" >REPORT PROBLEM</button>
                    </div>
                </div>
            </div>                 
        </div>        
    </div>
</div>

