import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
    private token: string;
    private apiUrl = environment.apiUrl;
    
    constructor(private _http : HttpClient) {
        this.token = '';
    }
    

    setToken(token: string): void {
        this.token = token;
        localStorage.setItem('auth-token', token);
    }

    public loginUser(email: string, password: string) {
        return this._http.post<Response>(this.apiUrl + '/auth', {email: email , pass: password});
    }
}