import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotfoundComponent } from './page-notfound/page-notfound.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { ForgotPassComponent } from './forgot-pass/forgot-pass.component';
import { PaymentFailedComponent } from './payment-failed/payment-failed.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  { path: '',
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
  },
  { path: 'auth',
    component: AuthLayoutComponent,
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'auth/sign-in',
    component: SignInComponent,
  },
  {
    path: 'auth/forgot-pass',
    component: ForgotPassComponent,
  },
  {
    path: 'payment-failed',
    component: PaymentFailedComponent,
  },
  {
    path: '**',
    component: PageNotfoundComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
