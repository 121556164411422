import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-auth',
  templateUrl: './header-auth.component.html',
  styleUrls: ['./header-auth.component.scss']
})
export class HeaderAuthComponent implements OnInit {

  public token = localStorage.getItem('user-data');
  public name : string | undefined;
  constructor(private router : Router){
    
  }
  ngOnInit(): void {
    const sessionData = JSON.parse(this.token != null ? this.token: '')
    this.name = sessionData.firstName + ' ' + sessionData.lastName;
  }

  public statusDropdown : Boolean = false;

  toogleDropDown(){
    this.statusDropdown = !this.statusDropdown;
  }

  logOut(){
    localStorage.removeItem('auth-token');
    localStorage.removeItem('user-data');
    this.router.navigate(['/auth/sign-in']);
  }
}
