import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from './validation-pass';
import { UserService } from '../core/services/user.service';
import { PassRequest } from '../core/models/pass-request';

@Component({
  selector: 'app-forgot-pass',
  templateUrl: './forgot-pass.component.html',
  styleUrls: ['./forgot-pass.component.scss']
})


export class ForgotPassComponent {
  public passForm ! : FormGroup;

  public errorMessage : string | undefined;
  public sucessMessage : string | undefined;

  get frmSearch(): FormGroup['controls'] {
    return this.passForm.controls;
  }

  constructor(private fb : FormBuilder ,
    private userService: UserService){}
  
  ngOnInit(): void {
    this.passForm = this.buildForm();
  }

  private buildForm(){ 
    return this.fb.group({
      email : ['', [Validators.required , Validators.email]],
      pass : ['' , [Validators.required]],
      confirmPass : ['' , [Validators.required]]
    }, {
      validators: MustMatch('pass', 'confirmPass')
  });
  }

  sendForm(){
    if (this.passForm.valid) {
      
      this.userService.changePass(this.passForm.value.email, this.passForm.value.pass).subscribe({
        
        next: (ret : any) =>{
          this.sucessMessage = ret.message;
          this.passForm.reset();
        },
        error: (e) => {
          this.errorMessage = e.error.message;
        }
      })

    }
  }

  noValidField(sCampo: string): boolean | undefined {
    return this.frmSearch[sCampo]?.invalid && this.frmSearch[sCampo]?.touched;
  }

  listErrorsMessages(sCampo: string): string {
    const errors = this.frmSearch[sCampo]?.errors;

    if (errors?.['required']) return 'This field is required';
    if (errors?.['minlength']) return 'You must add more characters..';
    if (errors?.['maxlength'])
      return `Cannot exceed 30 ${errors?.['maxlength'].requiredLength} characters.`;
    if (errors?.['pattern'])
      return 'It cannot contain special characters or blank spaces.';
    if (errors?.['email'])
      return 'The email does not contain the structure of an email';
    return '';
  }
}
