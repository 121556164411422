import {Component,  OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../core/services/user.service';
import { AuthService } from '../core/services/auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  public bInhabilitar: boolean = true;
  
  public errorMessage : string | undefined;

  public loginForm! : FormGroup;

  get frmSearch(): FormGroup['controls'] {
    return this.loginForm.controls;
  }

  constructor(private fb : FormBuilder,
    private router: Router,
    private userService : AuthService){}
  
  ngOnInit(): void {
    this.loginForm = this.buildForm();
  }
  
  private buildForm(){ 
    return this.fb.group({
      email : ['', [Validators.required , Validators.email]],
      pass : ['' , [Validators.required]],
    });
  }

  noValidField(sCampo: string): boolean | undefined {
    return this.frmSearch[sCampo]?.invalid && this.frmSearch[sCampo]?.touched;
  }

  listErrorsMessages(sCampo: string): string {
    const errors = this.frmSearch[sCampo]?.errors;

    if (errors?.['required']) return 'This field is required';
    if (errors?.['minlength']) return 'You must add more characters..';
    if (errors?.['maxlength'])
      return `Cannot exceed 30 ${errors?.['maxlength'].requiredLength} characters.`;
    if (errors?.['pattern'])
      return 'It cannot contain special characters or blank spaces.';
    if (errors?.['email'])
      return 'The email does not contain the structure of an email';
    return '';
  }

  sendForm(){
    if (this.loginForm.valid) {
      // Aquí puedes enviar los datos del formulario al servidor
      this.userService.loginUser(this.loginForm.value.email, this.loginForm.value.pass).subscribe({

        next: (ret : any) =>{
          localStorage.setItem('auth-token', ret.token);
          localStorage.setItem('user-data', JSON.stringify(ret.userData));
          // Aquí redirigir al usuario a la página de inicio
          this.router.navigate(['/auth/home'])
        },
        error: (e) => {
          this.errorMessage = e.error.message;
        }
      }
    );
      
    }
  }

}
