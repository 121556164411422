import { Component } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
  public statusMenu : Boolean = false;
  public optionconfig : Boolean = false;
  public optionManageConfig : Boolean = false;
  public optionBlog : Boolean = false
  public optionPartner : Boolean = false;
  public optionService : Boolean = false;
  public optionVenues : Boolean = false;

  
  toogleMenu(){
    this.statusMenu = !this.statusMenu;
  }

  toogleOption(opt: string ){
    if(opt == 'Configuration'){
      this.optionconfig  = !this.optionconfig;
    }else if(opt == 'Manage'){
      this.optionManageConfig  = !this.optionManageConfig;
    }else if (opt == 'Blogs'){
      this.optionBlog = !this.optionBlog;
    }else if (opt == 'Partners'){
      this.optionPartner = !this.optionPartner;
    }else if (opt == 'Service'){
      this.optionService = !this.optionService;
    }else if (opt == 'Venues'){
      this.optionVenues = !this.optionVenues;
    }
  }

  
}
